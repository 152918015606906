@import "../../global.scss";

.footer-section {
	padding: 80px 20px 0 20px;
	background: $red;
	.footer-container {
		max-width: $mw;
		margin: 0 auto;
		// @media (max-width: 600px) {
		// 	display: flex;
		// 	flex-direction: row-reverse;
		// 	justify-content: flex-end;
		// }
		@media (max-width: 600px) {
			display: flex;
			flex-direction: column;
		}

		.mobile-logo {
			border-bottom: 1px solid white;
			margin-bottom: 40px;
			h3 {
				font-family: $lato;
				font-style: italic;
				font-size: 30px;
				letter-spacing: -3px;
				color: white;
				cursor: pointer;
				margin-bottom: 10px;
			}
		}

		.footer-menu {
			display: flex;
			justify-content: space-between;
			@media (max-width: 600px) {
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: 30px;
			}
			.menu-item {
				display: flex;
				flex-direction: column;
				color: white;
				@media (max-width: 600px) {
					margin-bottom: 40px;
				}
				h3 {
					font-family: $lato;
					font-size: 20px;
					margin-bottom: 10px;
				}
				a {
					margin-top: 10px;
					color: white;
					font-size: 14px;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.footer-socials {
			margin-top: 40px;
			display: flex;
			flex-direction: column;
			@media (max-width: 600px) {
				margin-top: 0;
			}

			.mobile-follow {
				display: none;
				font-size: 20px;
				font-weight: 600;
				color: white;
				margin-bottom: 15px;
				@media (max-width: 460px) {
					display: block;
				}
			}

			ul {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				list-style: none;
				@media (max-width: 460px) {
					justify-content: space-between;
				}

				li {
					color: white;
					cursor: pointer;
					font-size: 14px;
					margin-left: 30px;
					@media (max-width: 460px) {
						margin-left: 0;
					}

					&:hover {
						text-decoration: underline;
					}

					&:first-of-type {
						margin-left: 0;
						text-decoration: none;
						font-size: 20px;
						font-weight: 600;
						cursor: default;
						transform: translateY(-1px);
						@media (max-width: 460px) {
							display: none;
						}
					}
				}
			}

			.logo {
				@media (max-width: 432px) {
					display: none;
				}
				h3 {
					font-family: $lato;
					font-style: italic;
					font-size: 30px;
					letter-spacing: -3px;
					color: white;
					cursor: pointer;
				}
			}
		}
	}
	.copyright {
		display: flex;
		justify-content: center;
		text-align: center;
		margin-top: 30px;
		padding-bottom: 5px;
		p {
			font-size: 12px;
			color: white;
		}
		a {
			color: white;
			text-decoration: underline;
		}
	}
}
