@import "../../global.scss";

.three-section {
	padding: 80px 20px 100px 20px;
	background: $gray;

	@media (max-width: 800px) {
		padding: 40px 20px;
	}

	.three-container {
		max-width: $mw;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.three {
			width: 100%;
			h2 {
				font-size: 2.4rem;
				margin-bottom: 15px;

				@media (max-width: 490px) {
					font-size: 2.2rem;
				}

				@media (max-width: 432px) {
					font-size: 2.2rem;
				}

				@media (max-width: 400px) {
					font-size: 1.6rem;
				}
			}
			.cards-container {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-top: 60px;

				@media (max-width: 620px) {
					flex-direction: column;
				}
				.three-card {
					width: 30%;
					display: flex;
					flex-direction: row;
					align-items: flex-start;

					@media (max-width: 880px) {
						flex-direction: column;
					}

					@media (max-width: 620px) {
						flex-direction: row;
						width: 100%;
						margin-bottom: 40px;
					}

					.three-icon {
						position: relative;
						width: 50px;
						height: 50px;
						z-index: 0;

						@media (max-width: 620px) {
							width: 100px;
							height: 100px;
						}

						@media (max-width: 440px) {
							width: 50px;
							height: 50px;
						}

						// &:before {
						// 	content: "";
						// 	position: absolute;
						// 	width: 25px;
						// 	height: 25px;
						// 	border-radius: 50px;
						// 	background: $red;
						// }

						img {
							max-width: 50px;
							transform: translateY(10px);
							filter: drop-shadow(1px 1px 0 $black);

							@media (max-width: 1000px) {
								max-width: 30px;
							}

							@media (max-width: 620px) {
								max-width: 50px;
							}

							@media (max-width: 440px) {
								max-width: 40px;
							}
						}
					}
					.three-info {
						position: relative;
						z-index: 2;
						margin-left: 20px;

						@media (max-width: 880px) {
							margin-left: 0;
						}

						@media (max-width: 620px) {
							margin-left: 20px;
						}
						h3 {
							margin-bottom: 10px;
							font-size: 1.8rem;
							font-weight: 600;
							font-family: $lato;
							color: $black;
							background: $gray;
							border-radius: 5px;

							@media (max-width: 880px) {
								font-size: 1.4rem;
							}
						}
						p {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}
}
