@import "../../global.scss";

.graybar {
	position: relative;
	background: $gray;
	padding: 8px 20px;
	z-index: 666;

	.gray-container {
		max-width: $mw;
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;

		div {
			margin-left: 30px;
			font-size: 12px;
		}

		.gray-location {
			display: flex;
			align-items: center;

			img {
				margin-right: 5px;
			}
		}

		.gray-phone {
			display: flex;
			align-items: center;

			img {
				margin-right: 5px;
			}

			&:hover {
				a {
					color: $red;
				}
			}
		}
	}
}
