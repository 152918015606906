@import "../../global.scss";

.savings-section {
	padding: 80px 20px;

	.savings-container {
		max-width: $mw;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@media (max-width: 620px) {
			flex-direction: column;
		}

		.question {
			width: 65%;
			@media (max-width: 620px) {
				width: 100%;
			}
			h2 {
				font-size: 2.4rem;
				margin-bottom: 15px;
				@media (max-width: 490px) {
					font-size: 2.2rem;
				}

				@media (max-width: 432px) {
					font-size: 2.2rem;
				}

				@media (max-width: 400px) {
					font-size: 1.6rem;
				}
			}
			img {
				width: 100%;
				margin-top: 60px;

				@media (max-width: 432px) {
					margin-top: 40px;
				}
			}
		}

		.answer {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			padding-left: 20px;
			@media (max-width: 620px) {
				margin-top: 20px;
				padding-left: 0;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				column-gap: 20px;
			}
			@media (max-width: 545px) {
				grid-template-columns: 1fr;
				row-gap: 30px;
			}

			.card {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				h3 {
					margin-bottom: 10px;
					font-size: 2rem;
					font-weight: 600;
					font-family: $lato;
					color: $black;
					@media (max-width: 620px) {
						font-size: 1.4rem;
					}
				}
				p {
					margin-bottom: 10px;
					@media (max-width: 620px) {
						line-height: 1.4;
					}
				}
				a {
					font-weight: 700;
					color: $red;
					font-size: 15px;
					@media (max-width: 620px) {
						margin-top: 5px;
						font-size: 12px;
					}
				}
			}
		}
	}
}
