@import "../../global.scss";

.nav-section {
	padding: 16px 20px;
	position: sticky;
	top: 0;
	background: white;
	z-index: 666;
}

.nav-container {
	max-width: $mw;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	align-items: center;

	.logo {
		flex-grow: 1;
		flex-shrink: 0;

		img {
			width: 140px;
		}

		h3 {
			font-family: $lato;
			font-style: italic;
			font-size: 30px;
			letter-spacing: -3px;
			color: $red;
		}
	}

	.links {
		ul {
			list-style: none;
			display: flex;
			font-family: $martel;
			font-size: 13px;

			@media (max-width: 768px) {
				display: none;
			}

			li {
				margin-left: 2rem;

				&:hover {
					text-decoration: underline;
				}

				&:last-of-type {
					text-decoration: none;
					a {
						color: white;
						background: $red;
						padding: 6px 12px 4px 12px;
						border-radius: 5px;
						border: 1px solid $red;
						transition: all 0.1s linear;

						&:hover {
							background: white;
							color: $red;
						}
					}
				}
			}
		}
		.hamburger {
			display: none;
			max-width: 24px;
			width: 24px;
			height: 27px;
			max-height: 27px;
			cursor: pointer;
			transition: transform 0.1s linear;
			margin-top: 4px;

			@media (max-width: 768px) {
				display: block;
			}

			// @media (max-width: 370px) {
			// 	width: 24px;
			// }
		}
	}
}

.mobile-menu {
	display: none;
	padding: 20px;
	padding-top: 106px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: $red;
	z-index: 555;

	.mobile-links {
		ul {
			list-style: none;
			text-align: center;
			border-bottom: 1px solid white;
			padding: 20px 0;

			&:nth-of-type(2) {
				li {
					a {
						font-size: 1rem;
						font-weight: 500;
					}
				}
			}

			li {
				a {
					color: white;
					font-family: $martel;
					font-size: 2rem;
					font-weight: 700;
					display: block;
				}
			}
		}

		.mobile-social-icon-container {
			display: flex;
			justify-content: space-around;
			margin-top: 20px;

			img {
				max-height: 20px;
				filter: invert(100%);
				cursor: pointer;
			}
		}
	}
}
