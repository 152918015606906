@import "../../global.scss";

.blurred {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(255, 255, 255, 0.5);
	z-index: 999;
}

.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 30%;
	@media (max-width: 600px) {
		min-width: 80%;
	}
	height: auto;
	background-color: white;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	padding: 20px 20px 40px 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 9999;

	.content {
		position: relative;
		display: flex;
		justify-content: space-between;
		z-index: 9;

		.content-container {
			padding-right: 20px;
			h2 {
				font-size: 2.2rem;
				@media (max-width: 710px) {
					font-size: 1.8rem;
				}
				@media (max-width: 600px) {
					font-size: 1.4rem;
				}
			}

			p {
				text-shadow: 1px 1px 1px white;
			}

			.first {
				margin: 10px 0;
				font-weight: 700;
				color: $black;
				span {
					font-style: italic;
					text-decoration: underline;
				}
			}

			.third {
				text-decoration: underline;
				color: $red;
				font-weight: 600;
			}

			.fourth {
				margin-top: 12px;
				font-weight: 700;
				color: $black;
			}

			.fifth {
			}
		}
	}

	button {
		position: relative;
		max-width: 30px;
		height: 30px;
		flex-grow: 1;
		flex-shrink: 0;
		border: none;
		border-radius: 30px;
		background: none;
		cursor: pointer;
		z-index: 999;

		img {
			width: 100%;
			height: 100%;
			transition: transform 0.2s linear;
			opacity: 0.9;
			&:hover {
				transform: rotate(180deg);
			}
		}
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		//opacity: 0.3;
		border-radius: 15px;
		background-image: radial-gradient(circle, rgba(255, 255, 255, 0.5), $tan),
			url("../../assets/zain-mcintire-at-gmail.png");
		//background-image: radial-gradient(circle, #ffffff, #f4f3f3);
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: 100% 100%;
		z-index: 1;
	}
}
