@import "../../global.scss";

.about-section {
	padding: 0 20px;
	margin: 80px 0;

	@media (max-width: 800px) {
		margin: 40px 0;
	}

	.about-container {
		max-width: $mw;
		margin: $section;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 800px) {
			flex-direction: column-reverse;
		}

		.about-info {
			width: 55%;

			@media (max-width: 800px) {
				width: 100%;
				margin-top: 40px;
			}

			h2 {
				font-size: 2.4rem;
				margin-bottom: 30px;

				@media (max-width: 490px) {
					font-size: 2.2rem;
					margin-bottom: 20px;
				}

				@media (max-width: 432px) {
					font-size: 2.2rem;
				}

				@media (max-width: 400px) {
					font-size: 1.6rem;
				}
			}

			.btn-con {
				margin-top: 40px;
				display: flex;
				flex-direction: row;

				a {
					margin-right: 20px;

					@media (max-width: 346px) {
						font-size: 0.7rem;
					}
				}
			}
		}

		.about-video {
			width: 40%;
			position: relative;
			padding-bottom: 22.5%; /* 16:9 aspect ratio */
			height: 0;
			overflow: hidden;
			box-shadow: 5px 5px $red;

			@media (max-width: 800px) {
				width: 100%;
				padding-bottom: 56.25%; /* 16:9 aspect ratio */
			}

			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	.carriers {
		max-width: $mw;
		margin: 80px auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		img {
			max-width: 280px;
		}
	}
}
